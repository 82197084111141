export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyAgYe-oGt-ItjBp1_dOway3KU1YjeYjKxQ',
    authDomain: 'yin-thway.firebaseapp.com',
    databaseURL: 'https://yin-thway.firebaseio.com',
    projectId: 'yin-thway',
    storageBucket: 'yin-thway.appspot.com',
    messagingSenderId: '133902410612',
    appId: '1:133902410612:web:f0187afcda41f68e814221',
  },
  host: 'https://staging-api-yinthway.myancare.org',
  react_admin_panel_url: 'https://staging-panel-v2-yt.web.app',
  //host: "https://yinthway-express.ap-southeast-1.elasticbeanstalk.com",
  //host: "http://yinthway-env.eba-7d34zizj.ap-southeast-1.elasticbeanstalk.com",
  //host: "https://yt-api.myancare.org",
};
